/*
    Init calendar
*/
const copyBtn = document.getElementById('js-copy-calendar')
const copyBtnTooltip = document.getElementById('js-copy-feedback')

if (copyBtn) {
   copyBtn.addEventListener('click', () => {
      copyCalendarUrl()
   })
}

function copyCalendarUrl() {
   var copyText = document.getElementById('js-calendar-url')
   copyText.select()
   copyText.setSelectionRange(0, 99999)
   document.execCommand('copy')

   if (copyBtnTooltip.classList.contains('button__tooltip--active')) {
      copyBtnTooltip.classList.remove('button__tooltip--active')
   }

   setTimeout(() => {
      copyBtnTooltip.classList.add('button__tooltip--active')
   })
}

document.addEventListener('DOMContentLoaded', function () {
   var calendarEl = document.getElementById('calendar')

   if (calendarEl) {
      let contentHeight = window.innerHeight
      if (contentHeight > 1000) {
         contentHeight = 1000
      }

      var calendar = new FullCalendar.Calendar(calendarEl, {
         events: dates,
         locale: 'da',
         contentHeight,
         defaultView: window.innerWidth >= 985 ? 'month' : 'upcomming',
         header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,upcomming',
         },
         eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
         },
         displayEventEnd: true,
         views: {
            upcomming: {
               visibleRange: function (currentDate) {
                  console.log(new Date().setMonth(new Date().getMonth() + 1))
               },
               type: 'list',
               duration: { days: 30 },
               buttonText: 'Kommende',
            },
         },
      })

      calendar.render()
   }
})
