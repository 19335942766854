document.addEventListener('DOMContentLoaded', function () {
   const url = new URL(window.location.href)
   if (url.searchParams.get('edited')) {
      document
         .getElementById('organizing-' + url.searchParams.get('edited'))
         .scrollIntoView()
   }
})

var elem = document.querySelector('.organizing__cols');
var flkty = new Flickity(elem, {
   cellAlign: 'left'
});

var viewChanges = document.getElementsByClassName('js-change-view');


for (var i = 0; i < viewChanges.length; i++) {
   var view = viewChanges[i];
   view.addEventListener('click', (e) => {
      setCookie('view', e.target.getAttribute("data-view"), 365);
   });
}

function setCookie(name, value, days) {
   var expires = "";
   if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
   }
   document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
   var nameEQ = name + "=";
   var ca = document.cookie.split(';');
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
   }
   return null;
}
function eraseCookie(name) {
   document.cookie = name + '=; Max-Age=-99999999;';
}