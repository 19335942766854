document.addEventListener('DOMContentLoaded', function() {
   setTimeout(() => {
      document.getElementsByTagName('body')[0].classList.remove('preload')
   }, 100)

   setTimeout(() => {
      document
         .getElementById('js-mobile-trigger')
         .classList.remove('offcanvas__trigger--preload')
   }, 200)
})
