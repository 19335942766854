const navigationTrigger = document.getElementsByClassName(
   'js-offcanvas-trigger'
)
const navigationTriggerActiveClass = 'offcanvas__trigger--active'

const offcanvasNavigation = document.getElementById('js-offcanvas')
const offcanvasNavigationActiveClass = 'offcanvas--active'

const offcanvasBackdrop = document.getElementById('js-offcanvas-backdrop')
const offcanvasBackdropActiveClass = 'offcanvas__backdrop--active'

function openOffcanvas() {
   for (var trigger of navigationTrigger) {
      trigger.classList.add(navigationTriggerActiveClass)
   }

   offcanvasNavigation.classList.add(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.add(offcanvasBackdropActiveClass)
}

function closeOffcanvas() {
   for (var trigger of navigationTrigger) {
      trigger.classList.remove(navigationTriggerActiveClass)
   }

   offcanvasNavigation.classList.remove(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.remove(offcanvasBackdropActiveClass)
}

if (offcanvasBackdrop) {
   offcanvasBackdrop.addEventListener('click', () => {
      closeOffcanvas()
   })
}

if (navigationTrigger) {
   for (var trigger of navigationTrigger) {
      trigger.addEventListener('click', () => {
         if (
            offcanvasNavigation.classList.contains(
               offcanvasNavigationActiveClass
            )
         ) {
            closeOffcanvas()
         } else {
            openOffcanvas()
         }
      })
   }
}
