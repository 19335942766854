//Image gallery
const galleries = document.getElementsByClassName('js-gallery')

for (var gallery of galleries) {
   lightGallery(gallery, {
      mode: 'lg-fade',
      download: true,
   })
}

const expandGalleryTriggers = document.getElementsByClassName(
   'js-gallery-trigger'
)

Array.from(expandGalleryTriggers).forEach(imageTrigger => {
   imageTrigger.addEventListener('click', () => {
      imageTrigger.parentNode.classList.toggle('gallery--expanded')
      var newText = imageTrigger.getAttribute('data-expanded-text')
      var oldText = imageTrigger.textContent

      imageTrigger.setAttribute('data-expanded-text', oldText)
      imageTrigger.textContent = newText
   })
})
